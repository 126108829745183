import React, { ReactNode } from "react";
import AnchorButton from "../../components/AnchorButton/AnchorButton";
import { Col, Row } from "../../components/Grid";
import MakecodeSVG from "../../components/MakecodeSVG/MakecodeSVG";
import { classroomUrl } from "../../config";
import GetText from "../../i18n/GetText";
import { SmallMicrobitFaceIcon } from "../../icons/MicrobitFaceIcon";
import Text from "../Text/Text";
import styles from "./LetsCodeQuickLinks.module.scss";
import pythonIcon from "./python-icon.svg";

const EditorQuickLink = ({
  icon,
  descriptionId,
  buttonText,
  href,
  divider
}: {
  icon: ReactNode;
  descriptionId: string;
  buttonText: string;
  href: string;
  divider?: boolean;
}) => (
  <div className={divider ? styles.divider : undefined}>
    <Text variant="default" gutterBottom>
      <GetText id={descriptionId} />
    </Text>
    <div className={styles.buttonContainer}>
      <AnchorButton primary={true} to={href}>
        {icon}
        <span>{buttonText}</span>
      </AnchorButton>
    </div>
  </div>
);

const LetsCodeQuickLinks = () => (
  <>
    <Row justify="center">
      <Col sm={10}>
        <Text variant="h4">
          <GetText id="quick-links" />
        </Text>
      </Col>
    </Row>
    <Row justify="center">
      <Col sm={10} className={styles.links}>
        <EditorQuickLink
          icon={<MakecodeSVG height={25} width={25} choice="white" />}
          descriptionId="makecode-editor-quick-link-description"
          buttonText="MakeCode editor"
          href="https://makecode.microbit.org"
        />
        <EditorQuickLink
          icon={<img height={22} width={22} src={pythonIcon} alt="" />}
          descriptionId="python-editor-quick-link-description"
          buttonText="Python editor"
          href="https://python.microbit.org"
          divider
        />
        <EditorQuickLink
          icon={<SmallMicrobitFaceIcon />}
          descriptionId="microbit-classroom-quick-link-description"
          buttonText="micro:bit classroom"
          href={classroomUrl()}
        />
      </Col>
    </Row>
  </>
);

export default LetsCodeQuickLinks;
