import React from "react";
import CardContent from "../../components/CardContent/CardContent";
import { Col, Row } from "../../components/Grid";
import { ImageRatios } from "../../components/Image/Image";
import ImageCard from "../../components/ImageCard/ImageCard";
import Link from "../../components/Link/Link";
import WithSpacing from "../../components/Spacing/Spacing";
import Text from "../Text/Text";
import { MicrobitEditor } from "../../model/common";
import styles from "./LetsCodeOtherEditor.module.scss";

interface LetsCodeOtherEditorsProps {
  editors: MicrobitEditor[];
  featured: MicrobitEditor[];
}

const EditorCard = ({
  value: { name, image, url }
}: {
  value: MicrobitEditor;
}) => (
  <ImageCard
    widths={273}
    className={styles.card}
    aspectRatio={ImageRatios.Landscape_3_2}
    image={image}
    fit="fillmax"
    ignoreImageParams
    href={url}
  >
    <CardContent title={name} href={url} />
  </ImageCard>
);

const LetsCodeOtherEditors = ({
  editors,
  featured
}: LetsCodeOtherEditorsProps) => {
  const notFeatured = editors.filter(
    editor => !featured.find(f => f._id === editor._id)
  );
  return (
    <WithSpacing>
      <div className={styles.root}>
        <Row>
          {featured.map(editor => (
            <Col key={editor._id} md={4}>
              <EditorCard value={editor} />
            </Col>
          ))}
        </Row>
        <Text variant="defaultLight" as="ul">
          {notFeatured.map(editor => (
            <li key={editor._id}>
              <Link to={editor.url}>{editor.name}</Link>
            </li>
          ))}
        </Text>
      </div>
    </WithSpacing>
  );
};

export default LetsCodeOtherEditors;
