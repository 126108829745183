import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import BlockContent from "../components/BlockContent/BlockContent";
import BlockContentOutline from "../components/BlockContentOutline/BlockContentOutline";
import Card from "../components/Card/Card";
import Credits from "../components/Credits/Credits";
import LetsCodeOtherEditors from "../components/LetsCodeOtherEditors/LetsCodeOtherEditors";
import LetsCodeQuickLinks from "../components/LetsCodeQuickLinks/LetsCodeQuickLinks";
import TitleRow from "../components/TitleRow/TitleRow";
import PageLayout from "../layouts/PageLayout/PageLayout";
import TwoColumnLayout from "../layouts/TwoColumnLayout/TwoColumnLayout";
import {
  CommonContext,
  MicrobitEditor,
  SanityCampaign,
  SanityCreditedDocument,
  SanityPage,
  SanityRawContent
} from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import styles from "./LetsCodePage.module.scss";

export const pageQuery = graphql`
  query LetsCodePage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityLetsCodePage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      _rawContent(resolveReferences: { maxDepth: 4 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
    editors: allSanityMicrobitEditor(sort: { fields: name, order: ASC }) {
      nodes {
        _id
        _type
        name
        url
        image {
          ...SanitySimpleImage
        }
      }
    }
    editorConfig: sanityMicrobitEditorConfig {
      featuredEditors {
        _id
        _type
        name
        url
        image {
          ...SanitySimpleImage
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "letsCode" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const LetsCodePage = ({
  pageContext: { alternates, strings },
  data: { menus, page, editors, editorConfig, campaigns },
  location
}: LetsCodePageProps) => {
  const { title, _rawContent: content } = page;
  const customEmbeds = {
    otherEditors: () => (
      <LetsCodeOtherEditors
        editors={editors.nodes}
        featured={editorConfig.featuredEditors}
      />
    )
  };

  return (
    <PageLayout
      siteArea={SiteArea.HOME}
      metadata={{
        alternates,
        title,
        page
      }}
      menus={menus}
      strings={strings}
      location={location}
    >
      <TwoColumnLayout
        topRows={
          <>
            <TitleRow title={title} className={styles.titleRow} />
            <LetsCodeQuickLinks />
          </>
        }
        aside={
          <nav>
            <BlockContentOutline content={content} />
          </nav>
        }
        campaigns={campaigns.nodes}
        main={
          <Card type="main">
            <BlockContent
              content={content}
              customEmbeds={customEmbeds}
              cap="3/4"
            />
            <Credits
              translationSponsors={page.translationSponsors}
              licence={page.licence}
            />
          </Card>
        }
      />
    </PageLayout>
  );
};

export default LetsCodePage;

interface LetsCodePageProps {
  data: {
    menus: GlobalMenus;
    campaigns: { nodes: SanityCampaign[] };
    page: SanityPage &
      SanityCreditedDocument & {
        _rawContent: SanityRawContent;
      };
    editors: {
      nodes: MicrobitEditor[];
    };
    editorConfig: {
      featuredEditors: MicrobitEditor[];
    };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
